













































import { ACHIEVEMENTS, automaticAchievementUnlocking } from "@/api/achievementService";
import { connectDiscord } from "@/api/discordService";
import handleError from "@/helpers/errors";
import { useNotifier } from "@/providers/notifier";
import { useRouter } from "@/router";
import { RoutesConfig } from "@/router/Routes";
import { userModule } from "@/store/modules/user";
import { defineComponent, onMounted, ref } from "@vue/composition-api";

export default defineComponent({
  name: "DiscordRedirect",
  setup() {
    const { currentRoute, navigate } = useRouter();
    const notify = useNotifier();
    const isLoading = ref(true);
    const successfullyConnected = ref(false);

    onMounted(async() => {
      const fullPath = currentRoute.value.fullPath;
      let tokenType = "";
      let accessToken = "";
      if (fullPath.includes("#") && fullPath.includes("&")) {
        const params = fullPath.split(/#|&/);
        params.map((param: string) => {
          if (param.includes("token_type")) tokenType = param.split("=")[1];
          if (param.includes("access_token")) accessToken = param.split("=")[1];
          return param;
        });
        if (tokenType && accessToken) {
          try {
            await connectDiscord(userModule.state.userId, tokenType, accessToken);
            await automaticAchievementUnlocking(ACHIEVEMENTS.DISCORD);
            notify({
              type: "success",
              title: "Du er nå med i vår offisielle Discord-server 🥳",
              message: "",
            });
            successfullyConnected.value = true;
          }
          catch (e) {
            handleError(e);
            notify({
              type: "error",
              title: "Noe gikk galt!",
              message: "Vi klarte ikke å koble til din Discord-bruker. Prøv igjen, eller ta kontakt med oss på chat.",
            });
          }
        }
      }
      isLoading.value = false;
    });
    return {
      isLoading,
      navigate,
      RoutesConfig,
      successfullyConnected,
    };
  },
});
