import learnlinkAPI from "@/api/learnlinkAPI";

export async function connectDiscord(uid: string, tokenType: string, accessToken: string): Promise<void> {
  const discordRes = await learnlinkAPI.axios.post<void>(
    `/discord/connectUser/${uid}`, {
      tokenType,
      accessToken,
    },
  );
  return discordRes.data;
}
