import learnlinkAPI from "@/api/learnlinkAPI";
import Moment from "@/services/moment";
import { userModule } from "@/store/modules/user";
import Learnlink from "@learnlink/interfaces";

const ENDPOINT = "/app/achievements";

export enum ACHIEVEMENTS {
  DISCORD = "LWnzALdcIwEoAopTwYKi",
}

export async function getAllAchievements(): Promise<Learnlink.Achievement.default[]> {
  const achievementRes = await learnlinkAPI.axios.get<Learnlink.Achievement.default[]>(
    `${ENDPOINT}/all-achievements`
  );

  return achievementRes.data;
}

export async function unlockAchievementForStudent(studentUID: string, achievementID: string): Promise<void> {
  await learnlinkAPI.axios.post<void>(
    `${ENDPOINT}/${studentUID}/${achievementID}`
  );
}

export async function createAchievementSuggestion(achievement: Learnlink.Achievement.CreateSuggestion): Promise<Learnlink.Achievement.default> {
  const achievementRes = await learnlinkAPI.axios.post<Learnlink.Achievement.default>(
    `${ENDPOINT}/`,
    achievement,
  );

  return achievementRes.data;
}

export async function getAllAchievementSuggestions(): Promise<Learnlink.Achievement.default[]> {
  const achievementSuggestionRes = await learnlinkAPI.axios.get<Learnlink.Achievement.default[]>(
    `${ENDPOINT}/allAchievementSuggestions`
  );

  return achievementSuggestionRes.data;
}

export async function automaticAchievementUnlocking(achievement: ACHIEVEMENTS): Promise<void> {
  if (!userModule.state.user.achievements?.some(a => a.ID === achievement)) {
    const achievements = userModule.state.user.achievements || [];
    achievements.push({
      ID: achievement,
      achieved: Moment().unix(),
      read: 0,
    });
    await userModule.actions.updateUserObject({ achievements });
  }
}
